import styled from "@emotion/styled";

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: ${(props) => props.gap || 0}px;

    @media (max-width: 768px) {
        flex-direction: ${({ nowrap }) => nowrap ? 'row' : 'column'};
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex: ${(props) => props.flex || 1};
    gap: ${(props) => props.gap || 0}px;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    padding: ${(props) => props.padding || 0}px;
    padding-right: ${(props) => props.paddingRight || 0}px;
`;
