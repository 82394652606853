import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Row, Column } from '../../../utils/Layout';
import Screenshot from '../../../../assets/images/Screenshot.png';

const Container = styled.div`
    background-color: #E7E5E5;
    padding: 4px 0 0;
`;

const Content = styled.div`
    max-width: 1062px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
`;

const Title = styled.h2`
    font-family: "Gilroy-SemiBold", sans-serif;
    font-size: 40px;
    color: #000;
    margin-bottom: 24px;
    line-height: 1.2;
`;

const Subtitle = styled.p`
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 18px;
    color: #000;
    margin: 0 auto;
    line-height: 1.5;
`;

const ScreenshotSection = () => (
    <Container>
        <Content>
            <Title>
                Web-Based Pivot Control for
                <br />
                Maximum Efficiency
            </Title>
            <Column gap={24}>
                <Subtitle>
                    Using advanced Internet of Things (IoT) technologies and our web platform, PivotHawk provides farmers with the ability to quickly and efficiently monitor their irrigation systems all core within the field, but also from anywhere in the world, making it easier to control the work, including farm workers.
                </Subtitle>
                <Subtitle>
                    The intuitive dashboard offers instant system statuses, alerts specific to any system failures, or movement issues.
                </Subtitle>
                <div style={{height: '52px'}}></div>
                <img src={Screenshot} alt="Screenshot" />
            </Column>
        </Content>
    </Container>
);

export default ScreenshotSection;
