import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Row, Column } from "../../../utils/Layout";
import { BodyText } from "../../../utils/Text";
import founder1 from "../../../../assets/images/founder-1.jpg";
import founder2 from "../../../../assets/images/founder-2.jpg";
const Container = styled.div`
    background-color: #e7e5e5;
    padding: 40px 20px;
`;

const Content = styled.div`
    max-width: 1062px;
    margin: 0 auto;
    text-align: center;
`;

const Title = styled.h2`
    font-family: "Gilroy-SemiBold", sans-serif;
    font-size: 40px;
    font-weight: 300;
    color: #000;
    margin-bottom: 32px;

    @media (min-width: 768px) {
        font-size: 40px;
    }
`;

const FoundersGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        gap: 48px;
    }
`;

const FounderCard = styled.div`
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
`;

const FounderInfo = styled.div`
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00000066;
    padding: 32px;
    max-height: 192px;
    margin-top: auto;
`;

const FounderImage = styled.img`
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    width: 100%;
    border-radius: 8px;
`;

const FounderTitle = styled.div`
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    color: #fff;
    margin-bottom: 8px;
    text-align: left;
    width: 100%;
`;

const FounderName = styled.div`
    font-family: "Gilroy", sans-serif;
    font-size: 22px;
    color: #fff;
    margin-bottom: 24px;
    text-align: left;
    width: 100%;
    font-weight: 400;
    text-align: left;
    width: 100%;
`;

const FounderDescription = styled.p`
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
    text-align: left;
`;

const Button = styled.button`
    font-family: "Gilroy-SemiBold", sans-serif;
    font-size: 16px;
    color: #fff;
    background-color: #000;
    border-radius: 100px;
    padding: 16px 48px;
    cursor: pointer;
    margin-bottom: 60px;
    border: none;
`;

const FounderSection = () => (
    <Container>
        <Content>
            <Title>Built by Farmers, for Farmers</Title>
            <Row gap={24}>
                <Column>
                    <BodyText>
                        PivotHawk was created by people who understand <b>the daily struggles of farming</b>. We’ve experienced firsthand the frustration of <b>wasted time, high fuel costs, and unexpected pivot failures</b> working on the endless prairies of Alberta.
                    </BodyText>
                </Column>
                <Column>
                    <BodyText>
                        That’s why we built a <b>simple, affordable, and reliable</b> solution-designed <b>to make irrigation monitoring effortless</b>, so farmers can <b>focus on what truly matters: growing their crops and running their farms efficiently.</b>
                    </BodyText>
                </Column>
            </Row>
            <Button>Our Story</Button>
            <FoundersGrid>
                <FounderCard>
                    <FounderImage src={founder1} alt="Lawrence Schaeffer" />
                    <FounderInfo>
                        <FounderTitle>co-founder</FounderTitle>
                        <FounderName>Lawrence Schaeffer</FounderName>
                        <FounderDescription>
                            Agriculture professional with 15+ years in business
                            serving and managing irrigation needs for farmers.
                        </FounderDescription>
                    </FounderInfo>
                </FounderCard>
                <FounderCard>
                    <FounderImage src={founder2} alt="Maxim Goncharov" />
                    <FounderInfo>
                        <FounderTitle>co-founder</FounderTitle>
                        <FounderName>Maxim Goncharov</FounderName>
                        <FounderDescription>
                            Experienced entrepreneur with 8+ years in business,
                            including 5+ years managing an IT company.
                        </FounderDescription>
                    </FounderInfo>
                </FounderCard>
            </FoundersGrid>
        </Content>
    </Container>
);

export default FounderSection;
