import "./_Footer.scss";
import logo from "../../assets/images/logo-footer.svg";
import ln from "../../assets/images/mdi_linkedin.svg";
import fb from "../../assets/images/gg_facebook.svg";
import inst from "../../assets/images/ri_instagram-line.svg";
import artaxe from "../../assets/images/artaxe.webp";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    return (
        <footer>
            <div className="container">
                <div className="box">
                    <div className="first-box">
                        <img src={logo} alt="logo" />
                        <div>
                            <button
                                onClick={() =>
                                    navigate(`/about`, { replace: true })
                                }
                            >
                                Our Story
                            </button>
                        </div>
                    </div>
                    <div className="nav-box">
                        <div className="first-box-nav">
                            <ul className="nav" style={{ width: "100%" }}>
                                <li>
                                    <a href="/privacy">Policies & Agreements</a>
                                </li>
                                <li>
                                    <a href="/faq">FAQ</a>
                                </li>
                                <li>
                                    <a href="/location">Dealer Location</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.artaxe-studio.com/"
                                        target="_blank"
                                    >
                                        Products And Solutions
                                    </a>
                                </li>
                            </ul>
                            <ul className="social">
                                <li>
                                    <a
                                        target="_blank"
                                        href="https://www.instagram.com/pivot_hawk?igsh=MXN2dDQ1dXF4czltYw%3D%3D&utm_source=qr"
                                    >
                                        Instagram
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        href="https://www.facebook.com/pivothawk"
                                    >
                                        Facebook
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        href="https://www.linkedin.com/company/pivothawk/"
                                    >
                                        Linkedin
                                    </a>
                                </li>
                            </ul>
                            <div style={{ display: "flex", justifyContent: "space-between", justifyContent: "flex-start", gap: 40, paddingRight: 16, whiteSpace: "nowrap" }}>
                                <img src={artaxe} alt="artaxe" style={{ height: 36 }} />
                                <p className="copyright">
                                    © PivotHawk {new Date().getFullYear()}
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className="box-btn">
                                <button
                                    onClick={() =>
                                        navigate(`/about`, { replace: true })
                                    }
                                >
                                    Our Story
                                </button>
                            </div>
                        </div>

                        {/*<div>*/}
                        {/*    <ul>*/}
                        {/*        <li><p>Support: </p></li>*/}
                        {/*        <li><a href="mailto:mg@pivothawk.com">mg@pivothawk.com</a></li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                        {/*<div>*/}
                        {/*    <ul>*/}
                        {/*        <li><p>For proposals and partnership: </p></li>*/}
                        {/*        <li><a href="mailto:mg@pivothawk.com">mg@pivothawk.com</a></li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
