import "./_homeHeroSection.scss";
import bgImage from "../../../../assets/images/hero-bg.jpg";
import android from "../../../../assets/images/android-btn.svg";
import ios from "../../../../assets/images/ios-btn.svg";
import { useNavigate } from "react-router-dom";
import seedsOfBravery from "../../../../assets/images/seeds-of-bravery.png";
import styled from "styled-components";

const MainTitle = styled.h1`
    font-family: "Gilroy-Semibold", sans-serif;
    font-size: 50px;
    font-weight: 500;
    line-height: 1.2;
    color: #010035;
    margin-bottom: 16px;
    color: #fff;
    max-width: 800px;

    margin-bottom: 22px;

    @media (min-width: 768px) {
        font-size: 50px;

        br {
            display: block;
        }
    }

    @media (min-width: 1280px) {
        font-size: 100px;
    }

    br {
        display: none;
    }
`;

const SubTitle = styled.h2`
    font-family: "Gilroy-Semibold", sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 1.4;
    color: #fff;

    @media (min-width: 768px) {
        font-size: 32px;
    }

    @media (min-width: 1024px) {
        font-size: 40px;
    }
`;

const HomeHeroSection = ({ openPopup }) => {
    const scroll = () => {
        window.scrollTo({
            top: 400,
            behavior: "smooth",
        });
    };

    const navigate = useNavigate();

    return (
        <div className="hero-section">
            <div className="box" style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="container">
                    <div className="box-content">
                        <MainTitle>
                            An innovative yet <br /> easy-to-use
                        </MainTitle>
                        <SubTitle>
                            pivot irrigation monitoring solution
                        </SubTitle>
                    </div>
                    <div className="container bottom-section">
                        <div className="left-section">
                            <span className="text">How does it work?</span>
                            <a
                                onClick={() => scroll()}
                                className="anchor animate-bounce"
                            >
                                <svg
                                    width="51"
                                    height="51"
                                    viewBox="0 0 51 51"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        opacity="0.2"
                                        cx="25.5"
                                        cy="25.5002"
                                        r="17"
                                        transform="rotate(45 25.5 25.5002)"
                                        stroke="white"
                                    />
                                    <path
                                        d="M25.5 17.7222V33.2785M25.5 33.2785L33.2782 25.5003M25.5 33.2785L17.7218 25.5003"
                                        stroke="white"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </a>
                        </div>
                        <div className="right-section">
                            <span className="text">Supported by:</span>
                            <div className="logos">
                                <img
                                    src={seedsOfBravery}
                                    alt="Seeds of Bravery"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeHeroSection;
