import styled from "@emotion/styled";

export const BodyText = styled.p`
    font-family: "Gilroy", sans-serif;
    font-size: 18px;
    line-height: 1.5;
    color: #000;
    text-align: left;
    margin-bottom: 48px;

    b {
        font-family: "Gilroy-SemiBold", sans-serif;
        font-weight: 300;
    }
`;
