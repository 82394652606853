import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Row, Column } from "../../../utils/Layout";
import Logo from "../../../../assets/images/logo-dark.svg";

const Container = styled.div`
    background-color: #010035;
    padding: 60px 0;
    color: white;
`;

const Content = styled.div`
    max-width: 932px;
    margin: 0 auto;
    padding: 0 16px;
`;

const Title = styled.h2`
    font-family: "Gilroy-SemiBold", sans-serif;
    font-size: 40px;
    color: #fff;
    margin-bottom: 46px;
    line-height: 1.2;
    text-align: center;
    font-weight: normal;
`;

const ComparisonTable = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 10px;
    background-color: none;
    overflow: hidden;
`;

const TableCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    text-align: ${(props) =>
        props.justifyContent === "center" ? "center" : "left"};
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 14px;
    padding: 4px 8px;
    margin: 0;
    background-color: ${(props) => props.backgroundColor || "#010035"};
    color: ${(props) => props.color || "white"};
    min-height: 82px;

    @media (min-width: 768px) {
        padding: 28px 24px;
        font-size: 16px;
    }

    &:first-of-type {
        padding-left: 16px;
        border-bottom: 1px solid #e7e5e54d;
    }
    
    &:nth-of-type(2) {
        border-top: 1px solid #e7e5e54d;
        border-left: 1px solid #e7e5e54d;
        border-top-left-radius: 7px;
    }

    &:nth-of-type(2n), &:nth-of-type(2n+1) {
        border-bottom: 1px solid #e7e5e54d;
    }

    &:nth-of-type(3n+2) {
        border-left: 1px solid #e7e5e54d;
    }

    &:nth-of-type(16) {
        border-bottom: 1px solid transparent;
    }
    
    &:nth-of-type(17) {
        border-bottom-left-radius: 7px;
    }

    img {
        max-height: 24px;
        width: auto;
    }
`;

const CheckIcon = () => (
    <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_1642_4990)">
            <path
                d="M12.5 23.4375C6.45939 23.4375 1.5625 18.5406 1.5625 12.5C1.5625 6.45939 6.45939 1.5625 12.5 1.5625C18.5406 1.5625 23.4375 6.45939 23.4375 12.5C23.4375 18.5406 18.5406 23.4375 12.5 23.4375ZM12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
                fill="#010035"
            />
            <path
                d="M17.14 7.76511C17.1289 7.77615 17.1184 7.78786 17.1089 7.80015L11.6833 14.7137L8.41215 11.4425C7.95449 10.9849 7.2125 10.9849 6.75486 11.4425C6.29721 11.9002 6.29721 12.6422 6.75486 13.0998L10.8899 17.2348C11.3476 17.6925 12.0896 17.6925 12.5472 17.2348C12.5574 17.2247 12.567 17.2139 12.576 17.2027L18.8139 9.40542C19.2548 8.94659 19.2492 8.21714 18.7972 7.76511C18.3395 7.30746 17.5976 7.30746 17.14 7.76511Z"
                fill="#010035"
            />
        </g>
        <defs>
            <clipPath id="clip0_1642_4990">
                <path
                    d="M0 0H18C21.866 0 25 3.13401 25 7V18C25 21.866 21.866 25 18 25H0V0Z"
                    fill="white"
                />
            </clipPath>
        </defs>
    </svg>
);

const NoneIcon = () => (
    <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_1642_5028)">
            <path
                d="M12.5 23.4375C6.45939 23.4375 1.5625 18.5406 1.5625 12.5C1.5625 6.45939 6.45939 1.5625 12.5 1.5625C18.5406 1.5625 23.4375 6.45939 23.4375 12.5C23.4375 18.5406 18.5406 23.4375 12.5 23.4375ZM12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
                fill="white"
            />
            <path
                d="M7.26008 7.26008C7.56518 6.95497 8.05983 6.95497 8.36493 7.26008L12.5 11.3951L16.635 7.26008C16.9402 6.95497 17.4348 6.95497 17.74 7.26008C18.045 7.56518 18.045 8.05983 17.74 8.36493L13.6049 12.5L17.74 16.635C18.045 16.9402 18.045 17.4348 17.74 17.74C17.4348 18.045 16.9402 18.045 16.635 17.74L12.5 13.6049L8.36493 17.74C8.05983 18.045 7.56518 18.045 7.26008 17.74C6.95497 17.4348 6.95497 16.9402 7.26008 16.635L11.3951 12.5L7.26008 8.36493C6.95497 8.05983 6.95497 7.56518 7.26008 7.26008Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_1642_5028">
                <rect width="25" height="25" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

const WhyUsSection = () => (
    <Container>
        <Content>
            <Title>Why we are we the right choice?</Title>

            <ComparisonTable>
                <TableCell></TableCell>
                <TableCell justifyContent="center">
                    Available solutions
                </TableCell>
                <TableCell
                    justifyContent="center"
                    color="black"
                    backgroundColor="white"
                >
                    <img src={Logo} alt="Logo" />
                </TableCell>

                <TableCell>Cost and subscription</TableCell>
                <TableCell justifyContent="center">
                    $5000-$9000 and $300-$1500/year
                </TableCell>
                <TableCell
                    color="black"
                    justifyContent="center"
                    backgroundColor="white"
                >
                    $489 and $150/year
                </TableCell>

                <TableCell>Does not affect the warranty / leasing</TableCell>
                <TableCell justifyContent="center">
                    <NoneIcon />
                </TableCell>
                <TableCell
                    color="black"
                    justifyContent="center"
                    backgroundColor="white"
                >
                    <CheckIcon />
                </TableCell>

                <TableCell>The simplicity of the device</TableCell>
                <TableCell justifyContent="center">
                    <NoneIcon />
                </TableCell>
                <TableCell
                    color="black"
                    justifyContent="center"
                    backgroundColor="white"
                >
                    <CheckIcon />
                </TableCell>

                <TableCell>Easy to install</TableCell>
                <TableCell justifyContent="center">
                    <NoneIcon />
                </TableCell>
                <TableCell
                    color="black"
                    justifyContent="center"
                    backgroundColor="white"
                >
                    <CheckIcon />
                </TableCell>

                <TableCell>
                    Ensure that user-friendly web platform operates
                </TableCell>
                <TableCell justifyContent="center">
                    <NoneIcon />
                </TableCell>
                <TableCell
                    color="black"
                    justifyContent="center"
                    backgroundColor="white"
                >
                    <CheckIcon />
                </TableCell>
            </ComparisonTable>
        </Content>
    </Container>
);

export default WhyUsSection;
