import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Row, Column } from "../../../utils/Layout";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import {useEffect, useState} from "react";
import { BodyText } from "../../../utils/Text";

const Container = styled.div`
    background-color: #e7e5e5;
    padding: 20px 0 200px;

    @media (min-width: 768px) {
        padding: 40px 20px 200px;
    }

    div {
        min-width: 0;
    }
`;

const Content = styled.div`
    max-width: calc(1236px + 32px);
    padding: 0 16px;
    margin: 0 auto;
    text-align: center;
`;

const Title = styled.h2`
    font-family: "Gilroy-SemiBold", sans-serif;
    font-size: 40px;
    font-weight: 300;
    color: #000;

    @media (min-width: 768px) {
        font-size: 40px;
    }
`;

const Subtitle = styled.p`
    font-family: "Gilroy", sans-serif;
    font-size: 24px;
    font-weight: 300;
    color: #000;
    margin-bottom: 40px;
`;

const CalculatorGrid = styled.div`
    display: grid;
    gap: 40px;
    text-align: left;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const SliderContainer = styled.div`
    margin-bottom: 12px;
    padding: 0 8px;
`;

const SliderLabel = styled.label`
    display: block;
    font-family: "Gilroy-Semibold", sans-serif;
    font-size: 16px;
    color: #7c7c7c;
    margin-bottom: 16px;
`;

const SliderWrapper = styled.div`
    position: relative;
    padding-bottom: 14px;
`;

const UnitLabel = styled.div`
    font-family: "Gilroy-Semibold", sans-serif;
    font-size: 14px;
    color: #010035;

    &.money-spent {
        font-family: "Gilroy-Semibold", sans-serif;
        align-content: end;
        color: #7c7c7c;
    }
`;

const CurrentValue = styled.div`
    font-family: "Gilroy-SemiBold", sans-serif;
    font-size: 40px;
    color: #050531;
`;

const MaxValue = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    color: #666;
`;

const StyledSlider = styled(Slider)`
    color: #010035;

    .MuiSlider-rail {
        height: 2px;
        background-color: #d9d7d7;
        opacity: 1;
    }

    .MuiSlider-track {
        height: 2px;
        border-color: #010035;
        border-width: 2px;
        border-radius: 8px;
    }

    .MuiSlider-thumb {
        width: 16px;
        height: 16px;
        background-color: #010035;

        &:hover,
        &.Mui-focusVisible {
            box-shadow: 0 0 0 8px rgba(1, 0, 53, 0.1);
        }

        &::after {
            background-color: white;
            width: 17px;
            height: 17px;
            border: 0;
        }

        &::before {
            width: 10px;
            height: 10px;
            z-index: 1;
            background-color: #010035;
        }
    }
`;

const ResultsCard = styled.div`
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px;

    @media (min-width: 1024px) {
        padding: 40px;
    }
`;

const ResultsGrid = styled.div`
    display: grid;
    gap: 24px;
    padding: 20px 0 0;

    @media (min-width: 1024px) {
        padding: 40px 40px 0;
    }
`;

const ResultItem = styled.div`
    gap: 20px;
    
    grid-template-columns: 1fr 1.28fr 1.28fr;
    display: grid;

    @media (min-width: 768px) {
    }
`;

const ResultLabel = styled.div`
    font-family: "Gilroy", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    color: #7c7c7c;
`;

const ResultValue = styled.div`
    font-family: "Gilroy-SemiBold", sans-serif;
    font-size: 32px;
    color: #010035;
`;

const Divider = styled.div`
    height: 2px;
    background-color: #e7e5e5;
    width: 100%;
`;

const SavingsGrid = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
`;

const SavingsItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    border-radius: 8px;
`;

const SavingsLabel = styled.div`
    font-family: "Gilroy-Semibold", sans-serif;
    font-weight: 500;
    text-align: left;
    font-size: 16px;
    color: #7c7c7c;
`;

const SavingsValue = styled.div`
    font-family: "Gilroy-SemiBold", sans-serif;
    color: #010035;
    font-size: 24px;
    text-align: left;

    @media (min-width: 768px) {
        font-size: 40px;
    }
`;

const CalculatorInputs = ({ values, handleChange ,totalCost}) => {
    return (
        <div>
            <SliderContainer>
                <SliderLabel>Number of pivots (n)</SliderLabel>
                <Row gap={10}>
                    <UnitLabel>devices</UnitLabel>
                    <CurrentValue>{values.pivots}</CurrentValue>
                </Row>
                <SliderWrapper>
                    <Box sx={{ width: "100%" }}>
                        <StyledSlider
                            value={values.pivots}
                            onChange={handleChange("pivots")}
                            min={1}
                            max={5}
                            step={1}
                            aria-label="Number of pivots"
                        />
                    </Box>
                    <MaxValue>5 devices</MaxValue>
                </SliderWrapper>
            </SliderContainer>

            <SliderContainer>
                <SliderLabel>Average Distance (km) to pivots (D)</SliderLabel>
                <Row gap={10}>
                    <UnitLabel>kilometers</UnitLabel>
                    <CurrentValue>{values.distance}</CurrentValue>
                </Row>
                <SliderWrapper>
                    <Box sx={{ width: "100%" }}>
                        <StyledSlider
                            value={values.distance}
                            onChange={handleChange("distance")}
                            min={1}
                            max={20}
                            step={0.1}
                            aria-label="Average distance"
                        />
                    </Box>
                    <MaxValue>4 kilometers</MaxValue>
                </SliderWrapper>
            </SliderContainer>

            <SliderContainer>
                <SliderLabel>Checks per pivot per day (T)</SliderLabel>
                <Row gap={10}>
                    <UnitLabel>quantity</UnitLabel>
                    <CurrentValue>{values.checks}</CurrentValue>
                </Row>
                <SliderWrapper>
                    <Box sx={{ width: "100%" }}>
                        <StyledSlider
                            value={values.checks}
                            onChange={handleChange("checks")}
                            min={2}
                            max={4}
                            step={1}
                            aria-label="Checks per pivot"
                        />
                    </Box>
                    <MaxValue>2 quantity</MaxValue>
                </SliderWrapper>
            </SliderContainer>

            <SliderContainer>
                <SliderLabel>
                    Average Inches of water applied per pivot (I)
                </SliderLabel>
                <Row gap={10}>
                    <UnitLabel>inches</UnitLabel>
                    <CurrentValue>{values.inches}</CurrentValue>
                </Row>
                <SliderWrapper>
                    <Box sx={{ width: "100%" }}>
                        <StyledSlider
                            value={values.inches}
                            onChange={handleChange("inches")}
                            min={6}
                            max={12}
                            step={1}
                            aria-label="Water inches"
                        />
                    </Box>
                    <MaxValue>12 inches</MaxValue>
                </SliderWrapper>
            </SliderContainer>

            <div>
                <Row gap={10}>
                    <UnitLabel className="money-spent">Money spent:</UnitLabel>
                    <CurrentValue>${totalCost}</CurrentValue>
                </Row>
            </div>
        </div>
    );
};

const CalculatorSection = () => {
    const [values, setValues] = useState({
        pivots: 5,
        distance: 1,
        checks: 2,
        inches: 6,
    });
    const [totalCost, setTotalCost] = useState(0);
    const [firstYearSavings, setFirstYearSavings] = useState(0);
    const [followingYearSavings, setFollowingYearSavings] = useState(0);

    const calculateFunction = () => {
        function calculateTotalCost(n, D, T, I) {
            return n * (T * 3 * I) * (30 * ((2 * D) / (D < 4 ? 40 : 80) + 0.333) + 0.504 * D);
        }

        function calculateSavings(totalCost, firstYearCost, recurringCost) {
            const firstYearSavings = totalCost - (firstYearCost + recurringCost);
            const followingYearSavings = totalCost - recurringCost;
            return { firstYearSavings, followingYearSavings };
        }

        const n = values.pivots;
        const D =values.distance;
        const T = values.checks;
        const I = values.inches;
        const firstYearCost = 1000;
        const recurringCost = 489;

        const totalCost = calculateTotalCost(n, D, T, I);

        const { firstYearSavings, followingYearSavings } = calculateSavings(totalCost, firstYearCost, recurringCost);

        // console.log({totalCost,firstYearSavings,followingYearSavings})

        setTotalCost(Math.round(totalCost/ 10) * 10)
        setFirstYearSavings(Math.round(totalCost - recurringCost))
        setFollowingYearSavings(Math.round(totalCost - 100))
    }
    useEffect(()=>{
        calculateFunction();
    },[values])
    const handleChange = (name) => (event, newValue) => {
        setValues((prev) => ({
            ...prev,
            [name]: newValue,
        }));
    };

    return (
        <Container>
            <Content>
                <Column
                    gap={30}
                    css={css`
                        display: none;
                    `}
                >
                    <Title>PivotHawk Savings Calculator</Title>
                    <Subtitle>
                        Find out how much time, fuel, and money you can save
                        with us!
                    </Subtitle>

                    <CalculatorGrid>
                        <CalculatorInputs
                            values={values}
                            handleChange={handleChange}
                            totalCost={totalCost}
                        />
                        <ResultsCard>
                            <ResultsGrid>
                                <ResultItem className="full-width">
                                    <ResultLabel>PivotHawk devices</ResultLabel>

                                    <ResultLabel>
                                        Initial PivotHawk investment cost
                                    </ResultLabel>

                                    <ResultLabel>
                                        Yearly subscription cost
                                    </ResultLabel>

                                    <ResultValue>{values.pivots}</ResultValue>
                                    <ResultValue>$489</ResultValue>
                                    <ResultValue>$100</ResultValue>
                                </ResultItem>
                            </ResultsGrid>

                            <Divider />

                            <SavingsGrid>
                                <SavingsItem>
                                    <Column gap={20}>
                                        <SavingsLabel>
                                            First year savings
                                        </SavingsLabel>
                                        <SavingsValue>${firstYearSavings}</SavingsValue>
                                    </Column>
                                </SavingsItem>
                                <SavingsItem>
                                    <Column gap={20}>
                                        <SavingsLabel>
                                            Following years savings
                                        </SavingsLabel>
                                        <SavingsValue>${followingYearSavings}</SavingsValue>
                                    </Column>
                                </SavingsItem>
                            </SavingsGrid>
                        </ResultsCard>
                    </CalculatorGrid>
                </Column>
                <BodyText style={{ maxWidth: 1140, marginTop: 20 }}>
                    Pivot failures can lead to a <b>5-15% reduction in yield</b>
                    , depending on the crop and downtime. For example, a corn
                    farmer <b>losing just 10% yield on 100 acres</b> could see{" "}
                    <b>losses of $15,000+ per season</b>, making real-time{" "}
                    <b>monitoring crucial for protecting profits.</b>
                </BodyText>
            </Content>
        </Container>
    );
};

export default CalculatorSection;
