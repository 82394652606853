import Header from "../header/Header";
import HomeHeroSection from "../section/home/hero/Hero";
import AboutProduct from "../section/home/aboutProduct/AboutProtuct";
import DeviceSection from "../section/home/device/DeviceSection";
import ScreenshotSection from "../section/home/screenshot/ScreenshotSection";
import WhyUsSection from "../section/home/why-us/WhyUsSection";
import FounderSection from "../section/home/founders/FounderSection";
import CalculatorSection from "../section/home/calculator/CalculatorSection";
import SubscribeSection from "../section/home/subscribe/SubscribeSection";
import Footer from "../footer/Footer";
import {useState} from "react";

import Popup from "../popup/Popup";
import MmailchimpSubscribe from "../popup/MmailchimpSubscribe";

const HomePage = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const openPopup = () => {
        setIsPopupOpen(true);
        document.body.classList.add('disable-scroll');
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        document.body.classList.remove('disable-scroll');
    };

    return (<>
            <Header openPopup={(v) => openPopup(v)} white={true} fixed={true}/>
            <HomeHeroSection openPopup={(v) => openPopup(v)}/>
            <AboutProduct/>
            <DeviceSection/>
            <ScreenshotSection/>
            <WhyUsSection/>
            <FounderSection/>
            <CalculatorSection/>
            <SubscribeSection/>
            <Footer/>
            <Popup isOpen={isPopupOpen} onClose={closePopup}>
               <MmailchimpSubscribe closeForm={closePopup}/>
            </Popup>
        </>
    )
}

export default HomePage
