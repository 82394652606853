import { css } from "@emotion/react";
import styled from "@emotion/styled";
import safe from "../../../../assets/images/safe-icon.svg";
import time from "../../../../assets/images/time.svg";
import money from "../../../../assets/images/money.svg";
import repair from "../../../../assets/images/repair.svg";
import heroProduct from "../../../../assets/images/Group 216.svg";
import arrowProduct from "../../../../assets/images/arrow-device.svg";

// Styled components
const Container = styled.div`
    background-color: #010035;
    color: white;
    padding: 80px 0;
`;

const Content = styled.div`
    max-width: 1272px;
    margin: 0 auto;
    padding: 0 16px;
`;

const HeaderSection = styled.div`
    display: flex;
    margin-bottom: 60px;
    gap: 30px;

    @media (max-width: 992px) {
        flex-direction: column;
    }
`;

const LeftColumn = styled.div`
    flex: 1;
`;

const RightColumn = styled.div`
    flex: 1;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 30px;

    @media (max-width: 992px) {
        border-left: none;
        padding-left: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        padding-top: 30px;
    }
`;

const Heading = styled.h2`
    font-family: "Gilroy-Medium", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #ffffff;
    margin-bottom: 24px;
`;

const HighlightedText = styled.span`
    color: #ffa800;
`;

const Subheading = styled.h3`
    font-size: 40px;
    font-family: Gilroy-SemiBold, sans-serif;
    font-weight: 400;
    margin: 54px 0 32px;
`;

const StatsSection = styled.div`
    position: relative;
    margin-bottom: 60px;
`;

const TimelineLine = styled.div`
    width: 69%;
    position: relative;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 0 auto 40px;
    margin-left: 16px;

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
    }
`;

const TimelinePoints = styled.div`
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    pointer-events: none;
`;

const TimelinePoint = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid white;
    background-color: #050531;
`;

const StatHeader = styled.div`
    font-family: Gilroy-Semibold, sans-serif;
    font-size: 24px;
    color: #f7941d;
    margin-bottom: 24px;
    text-align: center;

    @media (max-width: 768px) {
        margin-bottom: 16px;
    }
`;

const StatsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 30px;
    }
`;

const StatBox = styled.div`
    flex: 1;
    background-color: white;
    color: #050531;
    border-radius: 10px;
    padding: 30px 24px;
    margin: 0 8px;
    position: relative;

    &:first-child {
        margin-left: 0;
    }

    @media (max-width: 768px) {
        margin: 0;
    }

    &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 12px solid white;
        top: -12px;
        left: 20px;
        transform: translateX(0);
    }
`;

const StatDescription = styled.p`
    font-size: 18px;
    text-align: center;
    line-height: 1.5;
    text-align: left;
    font-family: "Gilroy-Medium", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
`;

const InfoBoxesContainer = styled.div`
    display: flex;
    background-color: rgba(255, 255, 255, 0.05);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.15) -20%, #9990 60%);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 7px;
    overflow: hidden;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const InfoBox = styled.div`
    flex: 1;
    padding: 0 16px 32px;
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &:last-child {
        border-bottom: none;
    }

    @media (min-width: 768px) {
        padding: 40px 30px;
        margin-top: 45px;
        margin-bottom: 45px;
        border-bottom: none;
        border-right: 1px solid rgba(255, 255, 255, 0.2);

        &:last-child {
            border-right: none;
        }
    }
`;

const IconContainer = styled.div`
    margin-bottom: 20px;

    svg {
        width: 40px;
        height: 40px;
    }
`;

const InfoText = styled.p`
    font-size: 18px;
    line-height: 1.5;
    font-family: "Gilroy", sans-serif;
`;

const BodyText = styled.p`
    font-family: "Gilroy", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
`;

const MobileStatsSection = styled.div`
    display: none;
    
    @media (max-width: 768px) {
        display: block;
    }
`;

const MobileTimeline = styled.div`
    position: relative;
    padding-left: 24px;
    margin-left: 16px;
    
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 24px; // Adjust based on metric height
        bottom: 24px;
        width: 2px;
        background: rgba(255, 255, 255, 0.2);
    }
`;

const MobileTimelineItem = styled.div`
    position: relative;
    margin-bottom: 32px;
    
    &::before {
        content: '';
        position: absolute;
        left: -30px;
        top: 24px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: white;
        border: 2px solid #010035;
    }
`;

const MobileMetric = styled.div`
    font-family: "Gilroy-Semibold", sans-serif;
    font-size: 24px;
    color: #F7941D;
    margin-bottom: 16px;
`;

const MobileStatBox = styled.div`
    background: white;
    border-radius: 8px;
    padding: 16px;
    color: #050531;
`;

const DesktopStatsSection = styled(StatsSection)`
    display: block;
    
    @media (max-width: 768px) {
        display: none;
    }
`;

// Create separate components for mobile and desktop views
const MobileStats = () => (
    <MobileStatsSection>
        <MobileTimeline>
            <MobileTimelineItem>
                <MobileMetric>1.5 - 8 hr/day</MobileMetric>
                <MobileStatBox>
                    <StatDescription>
                        Farmers waste checking pivots (300+ hours per season)
                    </StatDescription>
                </MobileStatBox>
            </MobileTimelineItem>

            <MobileTimelineItem>
                <MobileMetric>$500 to $1.500</MobileMetric>
                <MobileStatBox>
                    <StatDescription>
                        Labor and travel expenses for routine manual checks per system
                    </StatDescription>
                </MobileStatBox>
            </MobileTimelineItem>

            <MobileTimelineItem>
                <MobileMetric>5 - 10% water</MobileMetric>
                <MobileStatBox>
                    <StatDescription>
                        Undetected malfunctions cause 5-10% excess water usage
                    </StatDescription>
                </MobileStatBox>
            </MobileTimelineItem>
        </MobileTimeline>
    </MobileStatsSection>
);

const DesktopStats = () => (
    <DesktopStatsSection>
        <div style={{ marginBottom: "60px" }}>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                width: "82%"
            }}>
                <StatHeader>1.5 - 8 hr/day</StatHeader>
                <StatHeader>$500 to $1.500</StatHeader>
                <StatHeader>5 - 10% water</StatHeader>
            </div>

            <TimelineLine>
                <TimelinePoints>
                    <TimelinePoint />
                    <TimelinePoint />
                    <TimelinePoint />
                </TimelinePoints>
            </TimelineLine>
        </div>

        <StatsContainer>
            <StatBox>
                <StatDescription>
                    Farmers waste checking pivots (300+ hours per season)
                </StatDescription>
            </StatBox>

            <StatBox>
                <StatDescription>
                    Labor and travel expenses for routine manual checks per system
                </StatDescription>
            </StatBox>

            <StatBox>
                <StatDescription>
                    Undetected malfunctions cause 5-10% excess water usage
                </StatDescription>
            </StatBox>
        </StatsContainer>
    </DesktopStatsSection>
);

const AboutProduct = () => {
    return (
        <Container className="about-product">
            <div className="about-product-anchor"></div>
            <Content>
                <HeaderSection>
                    <LeftColumn>
                        <Heading>
                            Farmers worldwide with a more than <br />
                            <HighlightedText>1 million+</HighlightedText> pivot
                            irrigation systems and <br />
                            <HighlightedText>200.000+</HighlightedText> in North
                            America
                        </Heading>
                    </LeftColumn>
                    <RightColumn>
                        <BodyText>
                            Constantly face the problem of access to effective
                            and affordable monitoring tools, as all available
                            option offered by global manufacturers are
                            expensive, difficult to install, restrictions in
                            leasing terms and lack of cross-platform solution.
                        </BodyText>
                    </RightColumn>
                </HeaderSection>

                <Subheading>They are losing</Subheading>
                
                <DesktopStats />
                <MobileStats />
                
                <InfoBoxesContainer>
                    <InfoBox>
                        <IconContainer>
                            <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.25 37.5001C5.55964 37.5001 5 36.9404 5 36.2501C5 35.5597 5.55964 35.0001 6.25 35.0001H8.75V32.5001C8.75 28.0248 11.3632 24.1623 15.1414 22.3508C15.8661 22.0032 16.25 21.4079 16.25 20.8771V19.123C16.25 18.5921 15.8661 17.9968 15.1414 17.6493C11.3632 15.8377 8.75 11.9753 8.75 7.5V5H6.25C5.55964 5 5 4.44036 5 3.75C5 3.05964 5.55964 2.5 6.25 2.5L33.75 2.50005C34.4404 2.50006 35 3.05972 35 3.75007C35 4.44043 34.4403 5.00006 33.75 5.00005L31.25 5.00002V7.5C31.25 11.9753 28.6368 15.8377 24.8586 17.6493C24.1339 17.9968 23.75 18.5921 23.75 19.123V20.8771C23.75 21.4079 24.1339 22.0032 24.8586 22.3508C28.6368 24.1623 31.25 28.0248 31.25 32.5001V35.0001H33.75C34.4404 35.0001 35 35.5597 35 36.2501C35 36.9404 34.4404 37.5001 33.75 37.5001H6.25ZM11.25 5.00005V7.50005C11.25 8.84174 11.552 10.1133 12.092 11.2501H27.908C28.448 10.1133 28.75 8.84174 28.75 7.50005V5.00005H11.25ZM18.75 20.8771C18.75 22.6277 17.5547 23.9662 16.2223 24.6051C13.2785 26.0166 11.25 29.0227 11.25 32.5001C11.25 32.5001 13.4144 29.2536 18.75 28.802V20.8771ZM21.25 20.8771V28.802C26.5856 29.2536 28.75 32.5001 28.75 32.5001C28.75 29.0227 26.7215 26.0166 23.7777 24.6051C22.4453 23.9662 21.25 22.6277 21.25 20.8771Z"
                                    fill="#FFA800"
                                />
                            </svg>
                        </IconContainer>
                        <InfoText>
                            Time-consuming field visits take farmers away from
                            planting, harvesting, equipment maintenance, and
                            livestock
                        </InfoText>
                    </InfoBox>
                    <InfoBox>
                        <IconContainer>
                            <svg
                                width="37"
                                height="40"
                                viewBox="0 0 37 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 0H2.3125V37.5H37V40H0V0ZM23.125 28.7501C23.125 29.4404 23.6427 30.0001 24.2812 30.0001H33.5312C34.1698 30.0001 34.6875 29.4404 34.6875 28.7501V18.7501C34.6875 18.0597 34.1698 17.5001 33.5312 17.5001C32.8927 17.5001 32.375 18.0597 32.375 18.7501V25.2483L24.0199 14.2085C23.813 13.9352 23.5089 13.7692 23.1826 13.7516C22.8563 13.734 22.5384 13.8665 22.3074 14.1162L16.3267 20.5818L7.8726 8.01487C7.49701 7.45656 6.77387 7.33312 6.25743 7.73917C5.74098 8.14522 5.6268 8.92699 6.0024 9.4853L15.2524 23.2353C15.4513 23.531 15.7599 23.7177 16.0971 23.7463C16.4342 23.7749 16.766 23.6425 17.0051 23.384L23.0392 16.8607L31.0913 27.5001H24.2812C23.6427 27.5001 23.125 28.0597 23.125 28.7501Z"
                                    fill="#FFA800"
                                />
                            </svg>
                        </IconContainer>
                        <InfoText>
                            Undetected system failures = yield loss, wasted
                            water, and costly repairs
                        </InfoText>
                    </InfoBox>
                    <InfoBox>
                        <IconContainer>
                            <svg
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M24.75 33.75C29.7206 33.75 33.75 29.7206 33.75 24.75C33.75 19.7794 29.7206 15.75 24.75 15.75C19.7794 15.75 15.75 19.7794 15.75 24.75C15.75 29.7206 19.7794 33.75 24.75 33.75ZM36 24.75C36 30.9632 30.9632 36 24.75 36C18.5368 36 13.5 30.9632 13.5 24.75C13.5 18.5368 18.5368 13.5 24.75 13.5C30.9632 13.5 36 18.5368 36 24.75Z"
                                    fill="#FFA800"
                                />
                                <path
                                    d="M21.2344 26.8748C21.3417 28.2158 22.4003 29.261 24.3029 29.3848V30.375H25.147V29.3779C27.114 29.2404 28.2656 28.1883 28.2656 26.6617C28.2656 25.2726 27.3858 24.5575 25.8122 24.1861L25.147 24.028V21.3324C25.991 21.4286 26.5275 21.8894 26.6562 22.5289H28.1369C28.0296 21.2361 26.9209 20.2252 25.147 20.1152V19.125H24.3029V20.1358C22.622 20.3009 21.4776 21.3117 21.4776 22.7421C21.4776 24.0073 22.3288 24.8119 23.745 25.142L24.3029 25.2795V28.1401C23.4375 28.0095 22.8652 27.535 22.7365 26.8748H21.2344ZM24.2958 23.8285C23.4661 23.636 23.0154 23.244 23.0154 22.6527C23.0154 21.9925 23.5018 21.4974 24.3029 21.353V23.8285H24.2958ZM25.2686 25.5064C26.2771 25.7402 26.7421 26.1184 26.7421 26.7855C26.7421 27.5487 26.1627 28.0714 25.147 28.1676V25.4789L25.2686 25.5064Z"
                                    fill="#FFA800"
                                />
                                <path
                                    d="M2.25 0C1.00736 0 0 1.00736 0 2.25V20.25C0 21.4926 1.00736 22.5 2.25 22.5H11.4367C11.5666 21.7257 11.7624 20.9737 12.0182 20.25H6.75C6.75 17.7647 4.73528 15.75 2.25 15.75V6.75C4.73528 6.75 6.75 4.73528 6.75 2.25H29.25C29.25 4.73528 31.2647 6.75 33.75 6.75V14.6875C34.6052 15.453 35.3623 16.3259 36 17.285V2.25C36 1.00736 34.9926 0 33.75 0H2.25Z"
                                    fill="#FFA800"
                                />
                                <path
                                    d="M22.4962 11.4373C22.4987 11.3752 22.5 11.3127 22.5 11.25C22.5 8.76472 20.4853 6.75 18 6.75C15.5147 6.75 13.5 8.76472 13.5 11.25C13.5 12.7901 14.2737 14.1496 15.4537 14.9608C17.3584 13.1513 19.7908 11.892 22.4962 11.4373Z"
                                    fill="#FFA800"
                                />
                            </svg>
                        </IconContainer>
                        <InfoText>
                            Existing solutions are expensive, complex, and
                            brand-restricted
                        </InfoText>
                    </InfoBox>
                </InfoBoxesContainer>
            </Content>
        </Container>
    );
};

export default AboutProduct;
