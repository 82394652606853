import "./_Header.scss";
import whiteLogo from "../../assets/images/white-logo.svg";
import blackLogo from "../../assets/images/black-logo.svg";
import blackCart from "../../assets/images/cart-black.svg";
import whiteUser from "../../assets/images/user-white.svg";
import blackUser from "../../assets/images/user-black.svg";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo-footer.svg";

const Header = ({ openPopup, white = false, fixed = false }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isDashboard = location.pathname === "/dashboard";
    const handleEmailClick = () => {
        window.location.href =
            "mailto:mg@pivothawk.com?subject=PivotHawk%20customer%20support%20request&body=Your%20message";
    };
    const exit = () => {
        window.confirm("Do you really want to leave?");
        localStorage.clear();
        navigate("/login");
    };
    return (
        <header className={fixed ? "fixed" : "relative"}>
            <div className="container">
                <div className="box">
                    <div className="logo">
                        <a href="/">
                            <img
                                style={{
                                    position: "relative",
                                    zIndex: 1,
                                    width: 90,
                                }}
                                src={white ? whiteLogo : blackLogo}
                                alt="logo"
                            />
                        </a>
                    </div>
                    <div className="btn">
                        {isDashboard && (
                            <button
                                className={`${white ? "text-white" : "text-blue"} row-btn`}
                                onClick={() => handleEmailClick()}
                            >
                                <img
                                    src="data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z' fill='%23010035'/%3E%3Cpath d='M7.88267 8.67922C7.87128 8.88355 8.03986 9.04907 8.2445 9.04907H9.48123C9.68834 9.04907 9.85302 8.88019 9.88084 8.67496C10.0143 7.69077 10.6898 6.97339 11.8938 6.97339C12.9221 6.97339 13.8648 7.48755 13.8648 8.72534C13.8648 9.67749 13.303 10.1155 12.4175 10.782C11.4082 11.5151 10.6084 12.3721 10.6656 13.7622L10.67 14.0874C10.6728 14.2925 10.8399 14.4573 11.045 14.4573H12.2615C12.4686 14.4573 12.6365 14.2894 12.6365 14.0823V13.9241C12.6365 12.8481 13.0459 12.5339 14.1504 11.696C15.0645 11.001 16.0166 10.2297 16.0166 8.61108C16.0166 6.34497 14.1028 5.25 12.0081 5.25C10.1075 5.25 8.02446 6.13592 7.88267 8.67922ZM10.218 17.3232C10.218 18.123 10.856 18.7134 11.732 18.7134C12.646 18.7134 13.2744 18.123 13.2744 17.3232C13.2744 16.4949 12.646 15.9141 11.732 15.9141C10.856 15.9141 10.218 16.4949 10.218 17.3232Z' fill='%23010035'/%3E%3C/svg%3E%0A"
                                    alt="cart"
                                />
                                <span>support</span>
                            </button>
                        )}

                        <button
                            className={`${white ? "text-white" : "text-blue"} row-btn`}
                            onClick={() => navigate("/about")}
                        >
                            {white ? (
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.2487 13.4096C17.4626 15.0023 16.3987 16.5902 15.3086 18.0151C14.222 19.4355 13.1316 20.6652 12.3115 21.5407C12.2025 21.657 12.0985 21.767 12 21.8701C11.9015 21.767 11.7975 21.657 11.6885 21.5407C10.8684 20.6652 9.77802 19.4355 8.69137 18.0151C7.60131 16.5902 6.53737 15.0023 5.75134 13.4096C4.95721 11.8006 4.5 10.2929 4.5 9C4.5 4.85786 7.85786 1.5 12 1.5C16.1421 1.5 19.5 4.85786 19.5 9C19.5 10.2929 19.0428 11.8006 18.2487 13.4096ZM12 24C12 24 21 15.4706 21 9C21 4.02944 16.9706 0 12 0C7.02944 0 3 4.02944 3 9C3 15.4706 12 24 12 24Z" fill="white"/>
                                    <path d="M12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9C15 10.6569 13.6569 12 12 12ZM12 13.5C14.4853 13.5 16.5 11.4853 16.5 9C16.5 6.51472 14.4853 4.5 12 4.5C9.51472 4.5 7.5 6.51472 7.5 9C7.5 11.4853 9.51472 13.5 12 13.5Z" fill="white"/>
                                </svg>
                            ) : (
                                <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6865 10.0572C13.097 11.2517 12.299 12.4426 11.4815 13.5113C10.6665 14.5766 9.84873 15.4989 9.23364 16.1555C9.15191 16.2428 9.07387 16.3252 9 16.4026C8.92613 16.3252 8.84809 16.2428 8.76636 16.1555C8.15127 15.4989 7.33352 14.5766 6.51853 13.5113C5.70098 12.4426 4.90303 11.2517 4.31351 10.0572C3.7179 8.85046 3.375 7.71965 3.375 6.75C3.375 3.6434 5.8934 1.125 9 1.125C12.1066 1.125 14.625 3.6434 14.625 6.75C14.625 7.71965 14.2821 8.85046 13.6865 10.0572ZM9 18C9 18 15.75 11.6029 15.75 6.75C15.75 3.02208 12.7279 0 9 0C5.27208 0 2.25 3.02208 2.25 6.75C2.25 11.6029 9 18 9 18Z" fill="#010035"/>
                                    <path d="M9 9C7.75736 9 6.75 7.99264 6.75 6.75C6.75 5.50736 7.75736 4.5 9 4.5C10.2426 4.5 11.25 5.50736 11.25 6.75C11.25 7.99264 10.2426 9 9 9ZM9 10.125C10.864 10.125 12.375 8.61396 12.375 6.75C12.375 4.88604 10.864 3.375 9 3.375C7.13604 3.375 5.625 4.88604 5.625 6.75C5.625 8.61396 7.13604 10.125 9 10.125Z" fill="#010035"/>
                                </svg>
                            )}
                            <span>contact</span>
                        </button>

                        {!isDashboard ? (
                            <button
                                className={`${white ? "text-white" : "text-blue"} row-btn`}
                                onClick={() => navigate("/login")}
                            >
                                <img
                                    src={white ? whiteUser : blackUser}
                                    alt="login"
                                />
                                <span>login</span>
                            </button>
                        ) : (
                            <button
                                className={`${white ? "text-white" : "text-blue"} row-btn`}
                            >
                                <img
                                    src={white ? whiteUser : blackUser}
                                    alt="login"
                                />
                                <span onClick={() => exit()}>Exit</span>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
