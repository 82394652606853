import { css } from "@emotion/react";
import styled from "@emotion/styled";
import deviceImage from "../../../../assets/images/device-image.png";
import deviceInHandImage from "../../../../assets/images/device-in-hand.jpg";
import { Row, Column } from "../../../utils/Layout";

// Styled components
const Container = styled.div`
    background-color: #e7e5e5;
    padding: 80px 0;
`;

const Content = styled.div`
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
`;

const Title = styled.h2`
    font-family: "Gilroy-SemiBold", sans-serif;
    font-weight: 400;
    font-size: 40px;
    text-align: center;
    color: #010035;
    margin-bottom: 60px;
`;

const DeviceGridContainer = styled.div`
    overflow-x: auto;
`;

const DeviceGrid = styled.div`
    display: flex;
    flex-wrap: nowrap;
    width: fit-content;
    gap: 20px;

    & > img {
        aspect-ratio: 1/1;
        height: 350px;
        width: auto;
        object-fit: cover;
    }
`;

const DeviceCard = styled.div`
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    padding: ${(props) => props.padding || 0}px;
    display: flex;
    width: fit-content;
    min-width: ${(props) => props.minWidth || "610px"};
    align-items: flex-start;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    column-gap: 24px;

    &.image-card {
        display: grid;
        row-gap: 24px;
        column-gap: 0;
        padding: 0;
        overflow: hidden;
        place-content: start;

        & > div:first-of-type {
            display: flex;
        }

        img {
            width: 100%;
            height: 100%;
            max-height: 348px;
            object-fit: cover;
        }

        p {
            padding-left: 20px;
            padding-right: 20px;
        }

        @media (min-width: 768px) {
            display: flex;
            & > div:last-of-type {
                padding-top: 24px;
                padding-right: 12px;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        min-width: 500px;
        padding: ${(props) => `${props.padding}px` || "50px 20px 40px"};
        align-items: ${(props) => props.alignItemsMobile || "flex-start"};
        gap: 30px;
    }

    @media (max-width: 500px) {
        min-width: calc(100vw - 48px);
    }
`;

const DeviceImageContainer = styled.div``;

const DeviceContent = styled.div`
    flex: 1;
    gap: 16px;
`;

const ImportantLabel = styled.p`
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 16px;
    color: #7c7c7c;
`;

const HighlightedText = styled.span`
    font-family: "Gilroy-Bold", sans-serif;
`;

const DeviceDescription = styled.p`
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 18px;
    line-height: 1.5;
    color: #000;
    white-space: pre-line;
`;

const DeviceSection = () => {
    return (
        <Container>
            <Content>
                <Title>The device</Title>

                <DeviceGridContainer>
                    <DeviceGrid>
                        <DeviceCard padding={40} alignItemsMobile="center">
                            <DeviceImageContainer>
                                <img src={deviceImage} alt="PivotHawk device" />
                            </DeviceImageContainer>
                            <DeviceContent>
                                <Column gap={16}>
                                    <ImportantLabel>Importantly</ImportantLabel>
                                    <DeviceDescription>
                                        PivotHawk is a compact, weatherproof
                                        device that installs directly into the
                                        existing pivot light socket, making it a
                                        true plug-and-play solution.
                                    </DeviceDescription>
                                    <DeviceDescription>
                                        Powered by the pivot's on/off light
                                        indicator and connected to our cloud
                                        based platform, it continuously monitors
                                        system power status and movement.
                                    </DeviceDescription>
                                </Column>
                            </DeviceContent>
                        </DeviceCard>

                        <DeviceCard
                            padding={0}
                            minWidth="670px"
                            className="image-card"
                        >
                            <DeviceImageContainer>
                                <img
                                    src={deviceInHandImage}
                                    alt="PivotHawk device in hand"
                                />
                            </DeviceImageContainer>
                            <DeviceContent>
                                <Column gap={16}>
                                    <ImportantLabel>Importantly</ImportantLabel>
                                    <DeviceDescription>
                                        Unlike traditional monitoring systems,
                                        PivotHawk requires no professional
                                        installation and is compatible with all
                                        pivot irrigation systems, regardless of
                                        brand.
                                    </DeviceDescription>
                                </Column>
                            </DeviceContent>
                        </DeviceCard>
                        {/* <img
                            src={deviceInHandImage}
                            alt="PivotHawk device in hand"
                        />
                        <img
                            src={deviceInHandImage}
                            alt="PivotHawk device in hand"
                        /> */}
                    </DeviceGrid>
                </DeviceGridContainer>
            </Content>
        </Container>
    );
};

export default DeviceSection;
