import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import {useLocalStorage} from "../../../utils/useLocalStorage";
import styled from "styled-components";

import {useEffect, useState} from "react";

import {useNavigate} from 'react-router-dom';
import {Button, TextField, Checkbox} from "@mui/material";
import {useQuery} from "react-query";
import {signIn} from "../../../services/auth";
import {showSnackBar} from "../../Snackbar/Snackbar";

const LoginPage = () => {

    const navigate = useNavigate();

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", "");
    const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken", "");

    const [userID, setUserID] = useLocalStorage("userID", "");
    const [userEMail, setUserEmail] = useLocalStorage("userEmail", "");
    const [userRole, setRole] = useLocalStorage("role", "");

    const [credentials, setCredentials] = useState({email: '', password: ''})


    const {refetch: fetchSignIn, error,} = useQuery(['sign-in'], () => signIn({credentials}), {
        enabled: false,
        retry: false,
        onSuccess: (data) => {
            setAccessToken(data?.accessToken)
            setRefreshToken(data?.refreshToken)
            setUserID(data?._id)
            setUserEmail(data?.email)
            setRole(data?.role)
            navigate('/dashboard');
            showSnackBar("success", `Login: ${userEMail} successful!`)

        },
        onError: (err) => {
            console.log(err?.response)
            showSnackBar("error", `Login ${err?.response?.status === 401 ? 'Invalid credentials!' : error?.message}`)
        }
    })
    const handleSignIn = async () => {
        if (!credentials.email || !credentials.password) {
            showSnackBar("error", "Email and password are required!")
            return;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(credentials.email)) {
            showSnackBar("error", "Please enter a valid e-mail address")
            return;
        }

        try {
           const req= await fetchSignIn();
        } catch (error) {
            return showSnackBar("error", "There was an error logging in. Please try again")
        }
    };

    useEffect(() => {
      if(accessToken?.length > 0  && refreshToken?.length > 0 ){
          navigate('/dashboard');
      }
    }, []);

    const LoginWrapper = styled.div`
        display: flex;
        min-height: calc(100vh - 120px);
        padding: 20px;
        
        @media (min-width: 768px) {
            padding: 40px;
        }
    `;

    const Container = styled.div`
        font-family: "Gilroy-Regular", sans-serif;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        
        @media (min-width: 992px) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    `;

    const LoginBox = styled.div`
        max-width: 640px;
        margin: 0 auto;
    `;

    const Title = styled.h1`
        font-size: 24px;
        margin-bottom: 8px;
        color: #010035;
        margin-bottom: 24px;

        @media (min-width: 768px) {
            font-size: 32px;
        }

        @media (min-width: 992px) {
            font-size: 40px;
        }
    `;

    const Subtitle = styled.p`
        font-family: "Gilroy-SemiBold", sans-serif;
        font-size: 18px;
        color: #666;
        margin-bottom: 32px;

        @media (min-width: 768px) {
            font-size: 20px;
        }
        
        
    `;

    const Form = styled.form`
        display: flex;
        flex-direction: column;
        gap: 24px;
    `;

    const ImageContainer = styled.div`
        display: none;
        
        @media (min-width: 992px) {
            display: block;
            width: 50%;
            max-width: 600px;
            
            img {
                width: 100%;
                height: auto;
            }
        }
    `;

    const RememberMe = styled.div`
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: -8px;
    `;

    return (<>
        <Header/>
        <LoginWrapper>
            <Container>
                <LoginBox>
                    <Title>Hello, user!</Title>
                    <Subtitle>Currently, only pilot users have access to the platform. Subscribe for updates to be the first to get access.</Subtitle>
                    <Form>
                        <TextField
                            fullWidth
                            id="email"
                            label="Login"
                            variant="standard" 
                            value={credentials.email}
                            onChange={(e) => setCredentials({...credentials, email: e.target.value})}
                        />
                        <TextField
                            fullWidth
                            id="password"
                            label="Password"
                            type="password"
                            variant="standard"
                            value={credentials.password}
                            onChange={(e) => setCredentials({...credentials, password: e.target.value})}
                        />
                        <RememberMe>
                            <Checkbox size="small" />
                            <span>Stay logged in</span>
                        </RememberMe>
                        <Button 
                            variant="contained"
                            fullWidth
                            onClick={handleSignIn}
                            sx={{
                                padding: '16px 48px',
                                borderRadius: '100px',
                                backgroundColor: '#010035',
                                textTransform: 'none',
                                width: 'fit-content',
                                fontFamily: 'Gilroy-SemiBold',
                                fontSize: '18px',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#010035aa',
                                }
                            }}
                        >
                            Login
                        </Button>
                    </Form>
                </LoginBox>
                {/* <ImageContainer>
                    <img src="/placeholder-dashboard.png" alt="Dashboard preview" />
                </ImageContainer> */}
            </Container>
        </LoginWrapper>
        <Footer/>
    </>);
};

export default LoginPage
